import { useEffect, useState } from 'react'
import { SaveIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import EditForm from './Forms';
import Notification, { sendNotification } from '@appmaker/components/ReactHotToastIntegrator';
import { SwitchItem } from '@appmaker/core/components/AppmakerForm/components/SwitchItem';
import { useLocalStorage } from 'react-use';
import VSCodeEditor from 'packages/admin-tools/components/VSCodeEditor';
import Editor from '@monaco-editor/react';

const FormPreview = () => {
    const [formData, setFormData] = useLocalStorage('preview-formData', JSON.stringify({ "properties": { "name": { "type": "string", "label": "Sample Name", "description": "Sample Description" } } }, null, 2));
    // useState();
    const [appmakerForm, setAppmakerForm] = useState({});
    const [formValue, setFormValue] = useState({});
    const [realtime, setRealtime] = useLocalStorage('preview-formData-realtime', false);
    const onChange = (value) => {
        setFormData(value);
        if (realtime) {
            try {
                const data = JSON.parse(value);
                setAppmakerForm(data);
            } catch (error) {

            }
        }
    }
    useEffect(() => {
        try {
            const data = JSON.parse(formData);
            setAppmakerForm(data);
        } catch (error) {

        }
    }, [])
    const onRenderView = () => {
        try {
            const data = JSON.parse(formData);
            setAppmakerForm(data);
        } catch (error) {
            sendNotification('Invalid JSON', 'error');
        }
    }
    return (
        <Notification>
            <div class="flex">
                <div class="flex-auto items-center h-screen">
                    <div className="flex flex-col mx-2 my-10">
                        <div className='flex-1'>
                            {/* <textarea
                                value={formData}
                                rows={20}
                                onChange={onChange}
                                className='font-mono bg-gray-800 text-white w-full'
                                type='textarea'
                            /> */}
                            <Editor
                            className='font-mono bg-gray-800 text-white w-full'
                                //   onMount={(editor) => {
                                //     fileContentEditor.current = editor;
                                //   }}
                                width={'75vh'}
                                height="69vh"
                                defaultLanguage="json"
                                defaultValue={formData}
                                onChange={onChange}
                            />
                        </div>
                        <div className='flex items-center space-x-4 mt-4'>
                            <button
                                onClick={() => {
                                    onRenderView()
                                }}
                                type="button"
                                className="inline-flex items-center px-4 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-green-500"
                            >
                                <SaveIcon
                                    className={clsx('sm:-ml-1 sm:mr-2 m-0 h-5 w-5', {
                                        'animate-bounce': false,
                                    })}
                                    aria-hidden="true"
                                />
                                <span className="hidden sm:inline-flex">
                                    Render
                                </span>
                            </button>
                            <SwitchItem
                                defaultValue={realtime}
                                value={realtime}
                                onChange={(value) => { setRealtime(value) }}
                                properties={{
                                    name: 'switch',
                                    label: 'Realtime Preview',
                                    className: ""
                                }}
                            />

                        </div>
                        <div className='m-4'>
                            <pre>
                                {JSON.stringify(formValue, null, 2)}
                            </pre>
                        </div>
                    </div>
                </div>
                <div class="flex-1 bg-gray-300">
                    <EditForm
                        formValue={formValue}
                        showFormValue={(value) => {
                            setFormValue(value);
                        }}
                        schema={appmakerForm}
                    />
                </div>
            </div>
        </Notification>
    )
}

export default FormPreview