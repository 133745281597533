import { InnerBlocks } from '@wordpress/block-editor';
import { InspectorControls } from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { BlockCard } from '@appmaker/e-commerce-blocks/components';
import { extractTextFromHTML } from '../helper/index';
import {
	RichText
} from '@wordpress/block-editor';

import './style.css';

const Edit = ({ attributes, setAttributes }) => {
	const { config = {}, ___internal, showViewMoreButton, showTitle } = attributes
	return (
		<>
			<InspectorControls>
				<PanelBody title="Product Scroller">
					<ToggleControl
						label={'Show View more button'}
						checked={showViewMoreButton}
						onChange={() => {
							setAttributes({ showViewMoreButton: !showViewMoreButton });
						}}
					/>
					<ToggleControl
						label={'Show Title'}
						checked={showTitle}
						onChange={() => {
							setAttributes({ showTitle: !showTitle });
						}}
					/>

				</PanelBody>
			</InspectorControls>
			<BlockCard
				titleEditor={(
					<RichText
						allowedFormats={[]}
						value={attributes.title}
						withoutInteractiveFormatting
						onChange={(title) => {
							setAttributes({ title: extractTextFromHTML(title) });
						}}
						placeholder="Product Scroller Title"
					/>
				)}
				attributes={{
					showTitle: showTitle,
					accessButton: showViewMoreButton && (
						<RichText
							allowedFormats={[]}
							value={attributes.ctaText}
							withoutInteractiveFormatting
							onChange={(ctaText) => {
								setAttributes({ ctaText: extractTextFromHTML(ctaText) });
							}}
							placeholder="View More Button"
						/>
					),
				}}>

			</BlockCard>
			<InnerBlocks allowedBlocks={['appmaker/productscroller-item']} />
		</>
	);
};

export default Edit;
