import { getProjectTemplate } from '@appmaker/core/store/project';
import { CloudDownloadIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useState } from 'react';
import api from '../api/imports';
import { dispatch } from '@wordpress/data';

const MenuImport = () => {
	const [saving, setSaving] = useState(false);
	const template = getProjectTemplate();
	if (!template || template !== 'shopify') return null;
	const importMenu = async () => {
		const confirm = window.confirm(
			'This Action will replace the current page. Are you sure you want to continue?'
		);
		if (confirm) {
			setSaving(true);
			const handler = prompt(
				'Please enter the handler of the menu you want to import',
				'main-menu'
			);
			try {
				const response = await api.importShopifyMenu(handler);
				// appmaker.doAction('override-in-app-page-data', { blocks: response });
				dispatch('core/block-editor').resetBlocks(response);
			} catch (error) {}
			setSaving(false);
		}
	};
	return (
		<button
			onClick={() => {
				importMenu();
			}}
			type="button"
			className="relative inline-flex items-center px-4 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-500"
		>
			<CloudDownloadIcon
				className={clsx('sm:-ml-1 sm:mr-2 m-0 h-5 w-5', {
					'animate-bounce': saving,
				})}
				aria-hidden="true"
			/>
			<span className="hidden sm:inline-flex">
				{saving ? 'Importing...' : 'Import from shopify'}
			</span>
		</button>
	);
};

const MenuImportParent = (props = {}) => {
	if (!props.showMenuImports) {
		return null;
	}
	return <MenuImport {...props} />;
};

export default MenuImportParent;
