import { select } from '@wordpress/data';
// import { getInAppPage, loadInAppPages } from '../store';
import api from '../api';
const actions = {
    OPEN_INAPP_PAGE: {
        label: "Open InApp Pages",
        properties: {
            pageId: {
                type: 'promise-select',
                label: "Choose page",
                isCreatable: true,
                getOptions: async (inputValue, { projectId }) => {
                    try {
                        // await loadInAppPages()
                        const pageType = select('core/block-editor').getSettings().editorType;
                        const pageList = await api.getInAppPageList(pageType, inputValue)
                        // const pageList = getInAppPage().inAppPageList;
                        const finalList = pageList.map(item => ({ label: item.label, value: item.id }))
                        return finalList;
                    } catch (error) {
                        return []
                    }
                }
            },
            params: {
                label: "Params",
                type: 'string',
                parentObjectName: 'pageData'
            },
            openInModal: {
                type: "switch",
                label: "Open in Modal",
            }

        }
    }
};

export default actions;
