import {
	TrashIcon,
	ArrowUpIcon,
	ArrowDownIcon,
	ChevronDownIcon,
} from '@heroicons/react/solid';
import AppmakerFormWrapper from './AppmakerForm';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
function getDefaultValue(defaultValue) {
	return defaultValue.map((item) => {
		return {
			...item,
			___clientId: uuidv4(),
		};
	});
}
function removeClientId(data) {
	return data.map((item) => {
		const { ___clientId, ...rest } = item;
		return rest;
	});
}
function ExpandableView({
	children,
	item,
	properties,
	expandable = true,
	// ...props
}) {
	const nameField = properties.nameField;
	const title =
		item[nameField] ||
		item.title ||
		item.name ||
		item.label ||
		item.___clientId;
	const [expanded, setExpanded] = useState(false);

	return (
		<div className="w-full">
			<button
				onClick={() => setExpanded((e) => !e)}
				className={clsx(
					'flex w-full items-center justify-between p-2 bg-white shadow-md border border-gray-100',
					expanded ? 'rounded-t-md border-b-0 shadow-none' : 'rounded-md'
				)}
			>
				<p className="text-lg">{title}</p>
				<ChevronDownIcon
					className={clsx(
						'h-6 w-6 text-gray-500 transition-transform transform',
						{
							'rotate-180': expanded,
						}
					)}
				/>
			</button>
			<div className="transition-all ease-in-out duration-300">
				{expanded && children}
			</div>
		</div>
	);
}
const InfiniteForm = ({
	properties = {},
	defaultValue = [],
	value,
	onChange,
}) => {
	const {
		props = {},
		label,
		properties: form,
		addMoreLabel = '+ Add more',
		//  = 'coupon',
	} = properties;
	// const [input, setInput] = useState([
	//     {
	//         type: 'form',
	//     },
	// ]);
	const [valueArray, setValue] = useState(getDefaultValue(defaultValue) || []);

	const addInput = () => {
		setValue((e) => {
			return [
				...e,
				{
					___clientId: uuidv4(),
				},
			];
		});
	};
	const removeInput = (item) => {
		const index = valueArray.findIndex(
			(e) => e.___clientId === item.___clientId
		);
		// log
		setValue((e) => {
			return e.filter((e, i) => {
				return i !== index;
			});
		});
	};
	const moveUp = (block) => {
		// append to the index
		const index = valueArray.findIndex(
			(e) => e.___clientId === block.___clientId
		);
		const newArray = valueArray.map((item, arrayIndex) => {
			if (index === 0) {
				return item;
			}
			if (arrayIndex === index - 1) {
				return valueArray[index];
			}
			if (arrayIndex === index) {
				return valueArray[index - 1];
			}
			return item;
		});
		setValue(newArray);
	};
	const moveDown = (block) => {
		// append to the index
		const index = valueArray.findIndex(
			(e) => e.___clientId === block.___clientId
		);
		const newArray = valueArray.map((item, arrayIndex) => {
			if (index === valueArray.length - 1) {
				return item;
			}
			if (arrayIndex === index + 1) {
				return valueArray[index];
			}
			if (arrayIndex === index) {
				return valueArray[index + 1];
			}
			return item;
		});
		setValue(newArray);
	};

	const onChangeFormData = (data, index) => {
		setValue((s) => {
			const newArray = [...s];
			newArray[index] = data;
			return newArray;
		});
	};
	useEffect(() => {
		onChange(removeClientId(valueArray));
	}, [valueArray]);

	if (!form) {
		return null;
	}
	return (
		<div className="flex flex-col w-full mt-4">
			<div className="flex items-center justify-between">
				<label className="text-sm text-gray-800">{label}</label>
				<button
					onClick={addInput}
					className="text-indigo-600 bg-indigo-100 px-2 py-1 text-sm rounded-md hover:bg-indigo-200"
				>
					{addMoreLabel}
				</button>
			</div>
			{valueArray.map((item, index) => {
				return (
					<div
						className="border border-gray-200 p-2 rounded-md relative mt-2"
						key={`${item?.___clientId}-infinite-form`}
					>
						<div className="flex items-start gap-3">
							<div className="flex flex-col items-center space-y-0.5">
								<button
									className="text-blue-600 bg-blue-100 p-1 text-sm hover:bg-blue-200 rounded-t"
									onClick={() => {
										moveUp(item);
									}}
									title="Move up"
								>
									<ArrowUpIcon className="h-3 w-3" />
								</button>
								<button
									className="text-blue-600 bg-blue-100 p-1 text-sm hover:bg-blue-200 rounded-b"
									onClick={() => {
										moveDown(item);
									}}
									title="Move down"
								>
									<ArrowDownIcon className="h-3 w-3" />
								</button>
							</div>
							<ExpandableView item={item} properties={properties}>
								<AppmakerFormWrapper
									onChange={(data) => onChangeFormData(data, index)}
									schema={{ properties: form }}
									formValue={item || {}}
								/>
								<div className="flex justify-between mt-4">
									<button
										onClick={() => removeInput(item)}
										className="p-2 flex bg-red-100 text-sm items-center space-x-1 text-red-400 rounded-md hover:bg-red-200"
										title="Delete field"
									>
										<TrashIcon className="h-4 w-4" />
										<span>Delete</span>
									</button>
								</div>
							</ExpandableView>
						</div>
					</div>
				);
			})}
			{properties?.showAddButtonBelow ? (
				<div className="flex items-center justify-between">
					<button
						onClick={addInput}
						className="text-indigo-600 bg-blue-100 px-2 py-1 mt-4  text-sm rounded-md hover:bg-indigo-200"
					>
						{addMoreLabel}
					</button>
				</div>
			) : null}
		</div>
	);
};

export default InfiniteForm;
