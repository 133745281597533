import { ApolloClient, InMemoryCache } from '@apollo/client';
import { APPMAKER_GQL_URL } from '@appmaker/core/api/core';

const client = new ApolloClient({
	uri: APPMAKER_GQL_URL,
	credentials: 'include',
	cache: new InMemoryCache(),
});

export default client;
