// import appmaker from '@appmaker/core/index';
// appmaker.actions
// import { runDataSource } from '@appmaker/core/dataSource';
import { products, collection, tags, blogs, pages } from '../api';

const actions = {
	OPEN_PRODUCT_LIST: {
		label: 'Open all products',
	},
	OPEN_SEARCH: {
		label: 'Open search page',
	},
	OPEN_CART: {
		label: 'Open cart page',
	},
	OPEN_ZOOMABLE_IMAGE_SWIPER: {
		label: 'Open zoomable image swiper',
		properties: {
			imageList: {
				type: 'string',
				default: '',
				label: 'image list',
			},
			indexImage: {
				type: 'string',
				default: '',
				label: 'index image',
			},
		},
	},
	OPEN_WISHLIST: {
		label: 'Open wishlist page',
	},
	OPEN_PRODUCT: {
		label: 'Open single Product',
		properties: {
			productId: {
				type: 'promise-select',
				label: 'Open single product',
				getOptions: async (inputValue, { projectId }) => {
					try {
						const resp = await products({ search: inputValue });
						const finalList = resp.map((item) => ({
							label: item.label,
							value: item.id,
						}));
						return finalList;
					} catch (error) {
						console.log(error);
					}
				},
			},
		},
	},
	OPEN_COLLECTION: {
		label: 'Open Collection',
		properties: {
			collectionId: {
				type: 'promise-select',
				label: 'Choose collection',
				getOptions: async (inputValue, { projectId }) => {
					try {
						const resp = await collection({ search: inputValue });
						const finalList = resp.map((item) => ({
							label: item.label,
							value: item.id,
						}));
						return finalList;
					} catch (error) {
						console.log(error);
					}
				},
			},
			// tags: {
			// 	type: 'promise-select',
			// 	isMulti: true,
			// 	label: 'Choose tags (Optional)',
			// 	isSearchable: true,
			// 	isCreatable: true,
			// 	getOptions: async (inputValue, { projectId }) => {
			// 		try {
			// 			const resp = await tags({ search: inputValue });
			// 			const finalList = resp.map((item) => ({
			// 				label: item.label,
			// 				value: item.id,
			// 			}));
			// 			return finalList;
			// 		} catch (error) {
			// 			console.log(error);
			// 		}
			// 	},
			// },
			filters: {
				type: 'DataSourceSelector',
				label: 'Choose filter (Optional)',
				title: 'Choose filter',
				modalButtonSaveLabel: "Save filter",
				showSelectMessage: false,
				showSearch: false,
				properties: {
					tag: {
						type: 'promise-select',
						isMulti: true,
						label: 'Choose tags (Optional)',
						modalButtonSaveLabel: "Close",
						isSearchable: true,
						isCreatable: true,
						isConverted: true,
						getOptions: async (inputValue, { projectId }) => {
							try {
								const resp = await tags({ search: inputValue });
								const finalList = resp.map((item) => ({
									label: item.label,
									value: item.id,
								}));
								return finalList;
							} catch (error) {
								console.log(error);
							}
						},
					},
				}
			},
			title:{
                type:"string",
                label:"Title",
            },
		},
	},
	OPEN_BY_TAG: {
		label: 'Open products by tag',
		properties: {
			tags: {
				type: 'promise-select',
				isMulti: true,
				label: 'Product Id',
				getOptions: async (inputValue, { projectId }) => {
					try {
						const resp = await tags({ search: inputValue });
						const finalList = resp.map((item) => ({
							label: item.label,
							value: item.id,
						}));
						return finalList;
					} catch (error) {
						console.log(error);
					}
				},
			},
			title: {
				label: 'Title',
				type: 'string',
			},
		},
	},
	OPEN_BLOG_LIST: {
		label: 'Open Blog',
		properties: {
			blogId: {
				type: 'promise-select',
				label: 'choose blogs',
				getOptions: async (inputValue, { projectId }) => {
					try {
						const resp = await blogs({ search: inputValue });
						const finalList = resp.map((item) => ({
							label: item.label,
							value: item.id,
						}));
						return finalList;
					} catch (error) {
						console.log(error);
					}
				},
			},
			title: {
				label: 'Title',
				type: 'string',
			},
		},
	},
	// OPEN_PAGE: {
	// 	label: 'Open Page',
	// 	properties: {
	// 		pageId: {
	// 			type: 'promise-select',
	// 			label: 'choose page',
	// 			getOptions: async (inputValue, { projectId }) => {
	// 				try {
	// 					const resp = await pages({ search: inputValue });
	// 					const finalList = resp.map((item) => ({
	// 						label: item.label,
	// 						value: item.id,
	// 					}));
	// 					return finalList;
	// 				} catch (error) {
	// 					console.log(error);
	// 				}
	// 			},
	// 		},
	// 		title: {
	// 			label: 'Title',
	// 			type: 'string',
	// 		},
	// 	},
	// },
	// ADD_TO_CART: {
	// 	label: 'Add to cart',
	// },
	OPEN_LOGIN_PAGE: {
		label: 'Open login page',
	},
};

export default actions;
