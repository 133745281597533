import appmaker from '@appmaker/core/index';
import { DatabaseIcon } from '@heroicons/react/solid';
import { InnerBlocks } from '@wordpress/block-editor';

function CustomBlocks(props) {
	const { name, imageUrl, config, title } = props;
	const { innerBlockConfig } = config ?? {};
	const { allowedBlocks } = innerBlockConfig ?? {};
	return (
		<div
			key={name}
			className="relative border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
		>
			<div className="flex-shrink-0"></div>
			<div className="flex-1 min-w-0 text-center">
				<span className="absolute inset-0" aria-hidden="true" />
				{imageUrl ? (
					<img src={imageUrl} alt={name} />
				) : (
					<p className="text-sm font-medium text-gray-900">{title}</p>
				)}
				{innerBlockConfig && Object.keys(innerBlockConfig).length > 0 && allowedBlocks && (
					<>
						{allowedBlocks?.includes('*') ? (
							<InnerBlocks />
						) : (
							<InnerBlocks allowedBlocks={allowedBlocks} />
						)}
					</>
				)}
			</div>
		</div>
	);
}
// register blocks from db (themes and extensions)
const registerPartnerCustomBlocks = (blocks) => {
	try {
		blocks?.forEach((block) => {
			if (block.name) {
				appmaker?.registerAppmakerBlockType(block.name, {
					title: block?.title,
					icon: <DatabaseIcon />,
					Block: CustomBlocks,
					parent: block?.config?.parent,
					customProps: {
						...block,
					},
				});
			}
		});
	} catch (error) {
		console.log(error);
	}
};

export { registerPartnerCustomBlocks };
