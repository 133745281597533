import FileUpload from '@appmaker/components/Form/fields/FileUpload';
import ServiceUpload from '../helper/serviceUpload';
import { useFormData, setFormData, sendNotification } from '../../../store';
import { useProject } from '@appmaker/core/hooks';
import appmaker from '@appmaker/core/index';
const StepThree = () => {
	const formData = useFormData();
	const { projectId } = useProject();
	const { firebaseProjectId, currentStep } = formData;
	const afterUpload = () => {
		appmaker.doAction('appmaker-analytics-event', {
			event: 'firebase_configure_complete',
			params: {
				project_id: projectId
			}
		})
		setFormData({ ...formData, currentStep: currentStep + 1 });
	};
	return (
		<div className="w-full">
			<h2 className="text-lg font-medium text-gray-900">
				Upload service account
			</h2>
			<p className="mt-1 text-sm text-gray-500">
				Go to your selected firebase project Settings &gt; Service accounts and
				generate a new key
			</p>
			<ul className="list-inside flex flex-col space-y-6 mt-6 border-t border-gray-200 py-6">
				<li className="text-base font-normal">
					<span className="text-sm px-1.5 font-medium text-white rounded-full bg-blue-900 mb-1 mr-2">
						Step 1
					</span>
					<p>
						Navigate to service account tab in your project settings on Firebase
						console.
						<a
							target="_blank"
							rel="noreferrer"
							href="https://appmaker.xyz/en/docs/how-to-setup-firebase-account/"
							className="text-sm font-medium text-blue-600 hover:text-blue-500 block mt-1"
						>
							Firebase service account
							<span aria-hidden="true"> &rarr;</span>
						</a>
					</p>
				</li>
				<li className="text-base font-normal">
					<span className="text-sm px-1.5 font-medium text-white rounded-full bg-blue-900 mb-1 mr-2">
						Step 2
					</span>
					<p>
						Click the{' '}
						<a
							target="_blank"
							rel="noreferrer"
							className="underline text-blue-600 font-medium"
							href={`https://console.firebase.google.com/project/${firebaseProjectId}/settings/serviceaccounts/adminsdk`}
						>
							Generate New Private Key
						</a>{' '}
						button at the bottom of the Firebase Admin SDK section of the
						Service Accounts tab
					</p>
				</li>
				<li className="text-base font-normal">
					<span className="text-sm px-1.5 font-medium text-white rounded-full bg-blue-900 mb-1 mr-2">
						Step 3
					</span>
					<p>
						Upload the{' '}
						<span className="font-mono text-grey-900 bg-gray-200 font-bold py-0.25 px-0.5">
							.json
						</span>{' '}
						file in Appmaker dashboard.
					</p>
					<ServiceUpload
						id={projectId}
						addToast={sendNotification}
						afterUpload={afterUpload}
					/>
				</li>
			</ul>
		</div>
	);
};

export default StepThree;
