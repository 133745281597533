
import { entity } from 'simpler-state'
import coreApi from '../api/core';
import appmaker from '../index';
async function loadUser() {
    const userResp = await coreApi.user()
    if (userResp.logged_in)
        appmaker.doAction('appmaker-user-loggedin', userResp);
    return {
        ...userResp,
        isAdmin: userResp && userResp.email && userResp.email.includes('@appmaker.xyz')
    }
}
export const user = entity(loadUser())
export const useUser = () => user.use()
export const setUserId = (_userId) => user.set(value => ({ ...value, userId: _userId }))
export const setUser = (userData) => {
    user.set(value => ({ ...value, ...userData, loading: false }))
}
export const isAdmin = () => {
    const { isAdmin = false, email } = user.get();
    return isAdmin;
    // return email.includes('@appmaker.xyzs');
}
export const toggleAdmin = () => {
    user.set(value => ({ ...value, isAdmin: !value.isAdmin }))
}
export const getUser = ()=>{
    return user.get()
}