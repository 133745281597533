import appmaker from '@appmaker/core/index';
import { DeviceMobileIcon } from '@heroicons/react/solid';
import Home from './routes/Home';
import ThemePreview from './routes/ThemePreview';
import { registerCustomThemes } from './custom-theme-blocks'
const Plugin = {
    id: '@appmaker/themes',
    name: 'Themes',
    activate: () => {
        appmaker.registerRoute({
            routeName: '/themes/:themeId',
            component: ThemePreview,
        });
        appmaker.registerRoute({
            routeName: '/themes',
            component: Home,
        });
        /**
         * Menu Items
         */
        appmaker.registerSideBarMenu({
            name: 'Themes',
            routeName: '/themes',
            icon: DeviceMobileIcon,
        });

        /**
         * Register theme based blocks
         */
         registerCustomThemes();
    }
}

export default Plugin