import { CheckIcon } from '@heroicons/react/outline';
import api from '../../../api';
import { useProject } from '@appmaker/core/hooks';
import { useState } from 'react';
import { sendNotification } from '../../../store';
const StepFive = () => {
	const { projectId } = useProject();
	const [isLoading, setLoading] = useState(false);

	const updateApp = async () => {
		try {
			setLoading(true);
			const response = await api.buildApp(projectId);
			if (response.status) {
				// TODO message or redirection
				sendNotification('App build started', {
					appearance: 'success',
				});
				setInterval(() => {
					window.location.reload();
				}, 3000);
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};
	return (
		<div className="flex flex-col flex-auto items-center justify-center">
			<CheckIcon className="h-16 w-16 p-4 bg-green-100 text-green-600 rounded-full" />
			<h3 className="text-green-600 font-medium text-lg mt-2 mb-6">
				Configuration succesfully completed
			</h3>
			<h5 className="text-xl">
				Please <span className="font-bold">Update the app</span> to start
				sending push notifications
			</h5>
			<p className="text-sm text-gray-600 mb-6">
				Update the app and test push notification on your updated app.
			</p>
			<button
				type="button"
				className="inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
				onClick={updateApp}
				disabled={isLoading}
			>
				{isLoading ? 'Updating App...' : 'Update App'}
			</button>
		</div>
	);
};

export default StepFive;
