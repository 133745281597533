import create from 'zustand';
import produce from 'immer';
// const unsub1 = createStore.subscribe(console.log)
import createContext from 'zustand/context';
import { devtools } from 'zustand/middleware';

const createStore =
	({ formData = {}, _internal = {} }) =>
	() =>
		create(
			devtools((set) => ({
				_internal: _internal,
				formData: formData,
				initialFormData: formData,
				// schema,
				setFormData: (key, value) =>
					set(
						produce((state) => {
							state.formData[key] = value;
						})
					),
				setData: (data) => set(data),
				setWholeFormData: (formData) =>
					set(
						produce((state) => {
							state.formData = formData;
						})
					),
				setInternalData: (key, value) =>
					set(
						produce((state) => {
							state._internal[key] = value;
						})
					),
				setState: (cb) => set(produce(cb)),
				setOnSave: () =>
					set(
						produce((state) => {
							state.initialFormData = state.formData;
						})
					),
				// setData:
			}))
		);

const { Provider, useStore, useStoreApi } = createContext();

export { createStore, Provider, useStore, useStoreApi };
export {
	createStore as createAppmakerFormStore,
	Provider as AppmakerFormProvider,
	useStore as useAppmakerFormStore,
	useStoreApi as useAppmakerFormStoreApi,
};
