import axios from 'axios';
import { V3_BASE_URL } from '@appmaker/core/api/core';
import { getProjectId } from '@appmaker/core/store/project';

const apiFunction = {
  getInAppPageList: async (pageType = '', search) => {
    try {
      const projectId = getProjectId()

      let url = `${V3_BASE_URL}/projects/${projectId}/in-app-pages`;
      // `${BASE_URL}/projects/${projectId}/in-app-pages`
      const response = await axios.get(url, {
        params: {
          type: pageType,
          search
        },
        credentials: 'include',
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getInAppPage: async (inAppPageId) => {
    try {
      const projectId = getProjectId()

      const response = await axios.get(`${V3_BASE_URL}/projects/${projectId}/in-app-pages/${inAppPageId}`, {
        credentials: 'include',
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  save: async (pageId, data) => {
    const projectId = getProjectId()
    const response = await axios.post(`${V3_BASE_URL}/projects/${projectId}/in-app-pages/${pageId}`, data, {
      credentials: 'include',
      withCredentials: true,
    });
    return response.data;
  },
  create: async (pageType, blocks, pageKey, title, other = {}) => {
    try {
      const projectId = getProjectId()
      const response = await axios.post(`${V3_BASE_URL}/projects/${projectId}/in-app-pages`, {
        type: pageType,
        title,
        blocks,
        pageKey,
        ...other
      }, {
        credentials: 'include',
        withCredentials: true,
      });
      return response.data.body;
    } catch (error) {
      throw error;
    }
  },
  delete: async (pageId) => {
    const projectId = getProjectId()

    const response = await axios.delete(`${V3_BASE_URL}/projects/${projectId}/in-app-pages/${pageId}`, {
      credentials: 'include',
      withCredentials: true,
    });
    return response;
  },
  publishInAppPage: async (pageId, track) => {
    const projectId = getProjectId()
    let url = `${V3_BASE_URL}/projects/${projectId}/publish-app/in-app-pages/${pageId}`
    const response = await axios.post(url, { track }, {
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      withCredentials: true,
    });
    return response.data;
  },
  publishAppConfig: async (track) => {
    const projectId = getProjectId();
    let url = `${V3_BASE_URL}/projects/${projectId}/publish-app/app-config`
    const response = await axios.post(url, { track }, {
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      withCredentials: true,
    });
    return response.data;
  }
}

export default apiFunction;

