import { useFormData } from '@appmaker/core/index';
import { InnerBlocks } from '@wordpress/block-editor';
import loadash from 'lodash';

const ConditionBlock = ({
	attributes: { name },
}) => {
	return (
		<div className="w-full border border-purple-500 relative flex flex-col">
			<p className="text-center bg-purple-500 text-white text-sm inline-flex self-start px-2 rounded-br-md">
			{name ? name : "Condition Block"}
			</p>
			<div className="p-1">
				<InnerBlocks allowedBlocks={['appmaker/app-condition-block-item']} />
			</div>
		</div>
	);
};

const ConditionBlockItem = ({
	attributes: { name, conditions, condition_join_type },
}) => {
	return (
		<div className="w-full border border-purple-800 relative flex flex-col">
			<p className="text-center bg-purple-800 text-white inline-flex text-xs self-start px-2 rounded-br-md">
				{name ? name : "Condition"}
			</p>
			<div className="p-1">
				<InnerBlocks />
			</div>
		</div>
	);
};

ConditionBlock.attributesSchema = {
	properties: {
		name: {
			type: 'string',
			label: 'Name',
		}
	},
};

ConditionBlockItem.attributesSchema = {
	properties: {
		name: {
			type: 'string',
			label: 'Name',
		},
		condition_type: {
			type: 'string',
			label: 'When',
			uiType: 'select',
			options: [
				{
					label: 'Guest User',
					value: 'guest_user',
				},
				{
					label: 'User Logged In',
					value: 'user_logged_in',
				},
				// {
				// 	label: 'Has items in cart',
				// 	value: 'has_items_in_cart',
				// },
				// {
				// 	label: 'After Date',
				// 	value: 'after_date',
				// },
				// {
				// 	label: 'Before Date',
				// 	value: 'before_date',
				// },
				// {
				// 	label: 'Between Date',
				// 	value: 'between_date',
				// }
			],
		},
		raw_condition: {
			type: 'string',
			label: 'Raw Condition',
		},
		// conditions: {
		// 	type: 'array',
		// 	uiType: 'infinite-form',
		// 	label: 'Conditions',
		// 	properties: {
		// 		// condition_item: {
		// 		// 	type: 'string',
		// 		// 	label: 'Condition item',
		// 		// },
		// 		condition_item: {
		// 			type: 'string',
		// 			label: 'Condition item',
		// 			uiType: 'select',
		// 			options: [
		// 				{
		// 					label: 'User Logged In',
		// 					value: 'user_logged_in',
		// 				},
		// 				{
		// 					label: 'Date',
		// 					value: 'date',
		// 				},
		// 			],
		// 		},
		// 		condition_type: {
		// 			type: 'string',
		// 			title: 'Condition Type',
		// 			uiType: 'RadioGroup',
		// 			options: [
		// 				{
		// 					label: 'Equal',
		// 					value: 'equal',
		// 				},
		// 				{
		// 					label: 'Not Equal',
		// 					value: 'not_equal',
		// 				},
		// 			],
		// 		},
		// 		value: {
		// 			type: 'string',
		// 			label: 'Value',
		// 		},
		// 	},
		// },
		// condition_join_type: {
		// 	type: 'string',
		// 	title: 'Condition Join Type',
		// 	uiType: 'RadioGroup',
		// 	options: [
		// 		{
		// 			label: 'And',
		// 			value: 'and',
		// 		},
		// 		{
		// 			label: 'Or',
		// 			value: 'or',
		// 		},
		// 	],
		// },
	},
};
// export const ConditionBlockView = (props) => {
// 	const { block, children } = props;
// 	return children;
// 	return (
// 		<div>
// 			<p className="text-center bg-gray-100">Condition Block View</p>
// 			{/* <pre>{JSON.stringify(innerBlocks,null,2)}</pre> */}
// 			{children}
// 		</div>
// 	);
// };
// const sampleConditionAttr = {
// 	condition: 'condition',
// 	conditions: [
// 		{
// 			condition_type: 'equal',
// 			condition_item: 'giftType',
// 			value: 'cart_value',
// 		},
// 	],
// 	condition_join_type: 'or',
// };
function isMatchConditionItem(condition, formData) {
	const { condition_type, condition_item, value } = condition;
	const formValue = loadash.get(formData, condition_item);
	if (condition_type === 'equal') {
		return formValue === value;
	}
	return formValue !== value;
}
function isMatchCondition(condition, formData) {
	const { condition_join_type, conditions } = condition;
	if (condition_join_type === 'or') {
		return conditions.some((condition) =>
			isMatchConditionItem(condition, formData)
		);
	}
	return conditions.every((condition) =>
		isMatchConditionItem(condition, formData)
	);
}
export const ConditionBlockItemView = (props) => {
	const { block, children, attributes } = props;
	const formData = useFormData();
	const isMatch = isMatchCondition(attributes, formData);
	if (!isMatch) {
		return null;
	}
	return children;
};
// ConditionBlockItemView.canRenderInnerBlocks = true;
export { ConditionBlockItem, ConditionBlock };
