import { V3_BASE_URL } from '@appmaker/core/api/core';
import { getProjectId } from '@appmaker/core/store/project';
import axios from 'axios';

const api = {
	importShopifyMenu: async (handler) => {
		const projectId = getProjectId();
		let url = `${V3_BASE_URL}/projects/${projectId}/shopify/imports/menu?handler=${handler}`;
		const response = await axios.get(url, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
};

export default api;