import { useProject } from '@appmaker/core/hooks';
import { CogIcon, PlusCircleIcon, RefreshIcon } from '@heroicons/react/outline';
import { ArchiveIcon } from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import { useFormData, setFormData, changeStep } from '../../../store';
import api from '../../../api';
import LoadingIcon from 'packages/mobile-app/components/LoadingIcon';
import CreateApp from '../create-app';
import Select from 'react-select';
import clsx from 'clsx';
import { useQueryParam, BooleanParam } from 'use-query-params';

const StepTwo_DEPRECATED = () => {
	const project = useProject();
	const formData = useFormData();
	const [isLoading, setLoading] = useState(false);
	const { accessToken, currentStep } = formData;
	const { projectId } = project;
	const [items, setItems] = useState([]);

	const firebaseProjectClicked = async (firebaseProjectName) => {
		setFormData({
			...formData,
			firebaseProjectId: firebaseProjectName,
			currentStep: currentStep + 1,
		});
	};
	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await api.getFirebaseProjectList(projectId, accessToken);
			if (response && response.status) {
				setItems(response.body);
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	if (isLoading) {
		return <LoadingIcon className="text-blue-600 animate-spin h-16 w-16" />;
	}
	return (
		<div>
			<h2 className="text-lg font-medium text-gray-900">
				Your Firebase projects
			</h2>
			<p className="mt-1 text-sm text-gray-500">
				Please choose the project associated with this app.
			</p>
			<ul className="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
				<li className="flow-root">
					<a
						href="https://console.firebase.google.com/"
						target="_blank"
						rel="noreferrer"
						className="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-blue-500"
					>
						<div className="flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-gray-700">
							<PlusCircleIcon
								className="h-6 w-6 text-white"
								aria-hidden="true"
							/>
						</div>
						<div>
							<h3 className="text-sm font-medium text-blue-600">
								<div className="focus:outline-none">
									<span className="absolute inset-0" aria-hidden="true" />
									Create a new Firebase Project
									<span aria-hidden="true"> &rarr;</span>
								</div>
							</h3>
							<p className="mt-1 text-sm text-gray-500 line-clamp-2">
								Create New Project from Firebase console.
							</p>
						</div>
					</a>
				</li>
				{items.map((item, itemIdx) => (
					<li key={itemIdx} className="flow-root">
						<button
							key={itemIdx}
							className="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-blue-500"
							onClick={() => {
								firebaseProjectClicked(item.projectId);
							}}
						>
							<div className="flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-yellow-500">
								<ArchiveIcon
									className="h-6 w-6 text-white"
									aria-hidden="true"
								/>
							</div>
							<div>
								<h3 className="text-sm font-medium text-gray-900">
									<div className="focus:outline-none">
										<span className="absolute inset-0" aria-hidden="true" />
										{item.displayName}
										<span aria-hidden="true"> &rarr;</span>
									</div>
								</h3>
								<p className="mt-1 text-sm text-gray-500 line-clamp-2">
									{item.name}
								</p>
							</div>
						</button>
					</li>
				))}
			</ul>
			<div className="mt-4">
				<a
					target="_blank"
					rel="noreferrer"
					href="https://appmaker.xyz/en/docs/how-to-setup-firebase-account/"
					className="text-sm font-medium text-blue-600 hover:text-blue-500"
				>
					Learn more<span aria-hidden="true"> &rarr;</span>
				</a>
			</div>
		</div>
	);
};

const StepTwo = () => {
	const project = useProject();
	const formData = useFormData();
	const [isLoading, setLoading] = useState(false);
	const [selectedProject, setSelectedProject] = useState(undefined);
	const { accessToken, currentStep, userDetails = {} } = formData;
	const { email, displayName, photoURL } = userDetails;
	const { projectId } = project;
	const [items, setItems] = useState({
		projects: [],
		nextPageToken: null,
	});
	const [, setLoginCompleted] = useQueryParam('login_completed', BooleanParam);
	const fetchProjectList = async ({ nextPageToken } = {}) => {
		try {
			const response = await api.getFirebaseProjectList(
				projectId,
				accessToken,
				nextPageToken
			);
			setItems({
				projects: nextPageToken
					? items.projects.concat(response.projects)
					: response.projects,
				nextPageToken: response.nextPageToken,
			});
		} catch (error) {
			console.log(error);
			setLoginCompleted(0);
			changeStep(0);
		} finally {
			setLoading(false);
		}
	};
	const firebaseProjectClicked = async (firebaseProjectName) => {
		setFormData({
			...formData,
			firebaseProjectId: firebaseProjectName,
			currentStep: currentStep + 1,
		});
	};

	const loadMoreProjectList = (refresh) => {
		setLoading(true);
		fetchProjectList({
			nextPageToken: refresh ? undefined : items.nextPageToken,
		});
	};

	useEffect(() => {
		setLoading(true);
		fetchProjectList();
	}, []);

	// if (isLoading) {
	// 	return <LoadingIcon className="text-blue-600 animate-spin h-16 w-16" />
	// }
	return (
		<div className="w-full px-2">
			<div className="flex justify-between">
				<div>
					<h2 className="text-lg font-medium text-gray-900">
						Your Firebase projects
					</h2>
					<div
						key={email}
						className="mt-2 mb-2 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
					>
						<div className="flex-shrink-0">
							<img className="h-10 w-10 rounded-full" src={photoURL} alt="" />
						</div>
						<div className="flex-1 min-w-0">
							<div className="focus:outline-none">
								<span className="absolute inset-0" aria-hidden="true" />

								<p className="text-sm font-medium text-gray-900">
									{displayName}
								</p>
								<p className="text-sm text-gray-500 truncate">{email}</p>
							</div>
						</div>
					</div>

					<p className="mt-1 text-sm text-gray-500">
						Please choose the project associated with this app.
					</p>
				</div>
				<button
					className={clsx(
						'relative px-2 py-1 inline-flex self-end items-center space-x-1 text-sm font-medium rounded-md focus-within:ring-2 focus-within:ring-blue-500',
						isLoading ? 'bg-gray-100' : 'hover:bg-gray-50'
					)}
					disabled={isLoading}
					onClick={() => {
						loadMoreProjectList(true);
					}}
				>
					<div className="flex-shrink-0 flex items-center p-1 justify-center rounded-md ">
						<RefreshIcon
							className={clsx(
								'h-5 w-5 text-yellow-500',
								isLoading && 'animate-spin'
							)}
							aria-hidden="true"
						/>
					</div>
					<p className={isLoading ? 'text-gray-500' : 'text-gray-800'}>
						Refresh List
					</p>
				</button>
			</div>
			<ul className="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-6 sm:grid-cols-2 items-start">
				<li className="flow-root">
					<a
						href="https://console.firebase.google.com/"
						target="_blank"
						rel="noreferrer"
						className="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-blue-500"
					>
						<div className="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg bg-gray-700">
							<PlusCircleIcon
								className="h-6 w-6 text-white"
								aria-hidden="true"
							/>
						</div>
						<div>
							<h3 className="text-sm font-medium text-blue-600">
								<div className="focus:outline-none">
									<span className="absolute inset-0" aria-hidden="true" />
									Create a new Firebase Project
									<span aria-hidden="true"> &rarr;</span>
								</div>
							</h3>
							<p className="mt-1 text-sm text-gray-500 line-clamp-2">
								Create New Project from Firebase console.
							</p>
						</div>
					</a>
				</li>
				<li className="flow-root">
					<div className="flex flex-col">
						<div className="flex flex-col items-start">
							<div className="w-full">
								<label className="text-sm font-medium text-gray-800">
									Select Firebase project
								</label>
								<Select
									isClearable
									isLoading={isLoading}
									isDisabled={isLoading}
									options={items.projects}
									onChange={(value) => setSelectedProject(value)}
								>
									<option>Load more</option>
								</Select>
							</div>
							{selectedProject && (
								<button
									className="relative p-2 flex items-center text-white space-x-2 mt-4 rounded-md bg-blue-600 hover:bg-blue-700 focus-within:ring-2 focus-within:ring-blue-500"
									onClick={() => {
										firebaseProjectClicked(selectedProject.projectId);
									}}
								>
									<CogIcon className="h-5 w-5" aria-hidden="true" />
									<p>Configure</p>
								</button>
							)}
						</div>
						{items.nextPageToken && items.nextPageToken !== null && (
							<div className="py-2">
								{isLoading ? (
									<LoadingIcon className="text-blue-600 animate-spin " />
								) : (
									<button
										onClick={() => {
											loadMoreProjectList();
										}}
										disabled={isLoading}
										className="inline-flex items-center px-4 py-2 border border-transparent text-md font-medium rounded text-white bg-blue-600 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
									>
										Load more
									</button>
								)}
							</div>
						)}
					</div>
				</li>
			</ul>
			<div className="flex flex-row mt-4 items-center">
				<a
					target="_blank"
					rel="noreferrer"
					href="https://appmaker.xyz/en/docs/how-to-setup-firebase-account/"
					className="text-sm font-medium text-blue-600 hover:text-blue-500"
				>
					Learn more<span aria-hidden="true"> &rarr;</span>
				</a>
			</div>
		</div>
	);
};
export default StepTwo;
