import React from 'react';
import { withSelect } from '@wordpress/data';
import { PanelBody } from '@wordpress/components';
import { InspectorControls } from '@wordpress/block-editor';
import AysncSelect from './AsyncSelect';

const ProductScrollerItem = (props) => {
	const { className,
		attributes,
		setAttributes,
		products,
		isLoading,
		action,
		parentAttributes,
		onChangeParent } =
		props;
	const { id, labelId, productImageUrl, priceMeta: {
		maxVariantPrice: {
			amount
		} = {}
	} = {} } = attributes;

	const onChangeSelect = ({
		id, label, imageUrl, priceMeta
	}) => {
		// const productInfo = getProductInfo(productList,id);
		setAttributes({
			id: id,
			labelId: label,
			productImageUrl: imageUrl,
			priceMeta: priceMeta || {}
		});

		onChangeParent({
			ids: parentAttributes.ids ? [...parentAttributes.ids, id] : [id]
		})
	};
	return (
		<div className="w-32">
			<InspectorControls>
				<PanelBody title="Choose Product" initialOpen>
					<AysncSelect
						defaultValue={{
							id: id,
							label: labelId

						}
						}
						onChange={(value) => {
							onChangeSelect(value)
						}}
					/>
				</PanelBody>
			</InspectorControls>
			{id && (
				<div className="flex flex-col space-y-2 border border-gray-100 rounded-md ml-2 my-2 pb-2 overflow-hidden">
					<img src={productImageUrl} alt="product" className="w-full" />
					<div className="px-2">
						<p className="font-medium text-sm truncate">{labelId}</p>
						<p className="font-bold text-base">{amount}</p>
					</div>
				</div>
			)}
			{!id && (
				<div className="flex flex-col space-y-2 border border-gray-300 border-dashed rounded-md ml-2 my-2 pb-2 overflow-hidden bg-gray-100 h-48 items-center justify-center">
					<p className="text-sm text-gray-700">Select a product</p>
				</div>
			)}
		</div>
	);
};

export default withSelect((select, props, dispatch) => {
	const parentId = select('core/block-editor').getBlockRootClientId(
		props.clientId
	);
	const parentAttributes =
		select('core/block-editor').getBlockAttributes(parentId);
	const onChangeParent = value => {
		dispatch
			.dispatch('core/block-editor')
			.updateBlockAttributes(parentId, value);
	};
	return { parentAttributes, parentId, onChangeParent };
})(ProductScrollerItem);
