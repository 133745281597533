import clsx from 'clsx';
import ShopifyMediaLibrary from './Media';
import Modal from './Modal';
import { MediaProvider, createStore, useMediaStore } from './store/context';

const ToggleButton = ({ selected }) => {
	const toggleModal = useMediaStore((store) => store.toggleModal);
	return (
		<button
			className={clsx(
				'inline-flex rounded-md text-white',
				selected
					? 'bg-gray-600 px-2 py-1 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
					: 'bg-indigo-600 px-2 py-1 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
			)}
			onClick={() => {
				toggleModal();
			}}
		>
			{selected ? 'Replace media' : 'Open media library'}
		</button>
	);
};

const Media = (props) => {
	const { onChange = () => {}, defaultValue, properties = {} } = props || {};
	const {
		className,
		title,
		label,
		mediaTypes = ['image'],
		dropzoneHelperText,
		acceptedFile,
		disableImageResize = false,
		maxWidth,
		maxHeight,
	} = properties;
	const selected = defaultValue?.url;
	return (
		<MediaProvider createStore={createStore}>
			<div className={className ? className : 'mt-4'}>
				<p className={'block text-sm font-base text-gray-700 mb-1'}>
					{title ?? label}
				</p>
				<div
					className={clsx(
						'relative',
						selected &&
							'flex items-center border border-gray-400 rounded-md p-2 mt-1 justify-between space-x-2'
					)}
				>
					{defaultValue?.url && (
						<img
							src={selected}
							alt="img"
							className="h-auto object-contain w-28 max-h-28"
						/>
					)}
					<ToggleButton selected={selected} />
					<Modal
						title="Media Library"
						onSelect={(data) => {
							if (data?.mediaContentType?.toLowerCase() === 'image') {
								const newUrl = new URL(data?.url);
								if (!disableImageResize) {
									if (maxWidth && data?.meta?.width > maxWidth) {
										newUrl?.searchParams?.set('width', maxWidth);
									} else if (maxHeight && data?.meta?.height > maxHeight) {
										newUrl?.searchParams?.set('height', maxHeight);
									}
								}
								onChange({
									url: newUrl,
									id: data?.id,
									title: data?.title,
									meta: data?.meta ?? {},
								});
							} else {
								onChange({
									url: data?.url,
									id: data?.id,
									title: data?.title,
									sourceUrl: data?.video?.url,
									previewUrl: data?.url,
									meta: {
										...data?.meta,
										...data?.video,
									},
								});
							}
						}}
					>
						<ShopifyMediaLibrary
							mediaTypes={mediaTypes}
							dropzoneHelperText={dropzoneHelperText}
							acceptedFile={acceptedFile}
						/>
					</Modal>
				</div>
			</div>
		</MediaProvider>
	);
};

export default Media;
