// import GoogleLogin from '@appmaker/components/GoogleLogin';
import GoogleLogin from '@appmaker/components/GoogleLoginOauth';
import { LoginIcon } from '@heroicons/react/outline';
import { setFormData, useFormData } from '../../../store';
import { getProjectId, getProjectData } from '@appmaker/core/store/project';
import { GOOGLE_LOGIN_REDIRECT_URL } from '@appmaker/core/api/core';

const generateRedirectUrl = () => {
	const { projectData, projectId } = getProjectData();
	if (window.shopifyApp) {
		return projectData?.meta?.URL + '/admin/apps/appmaker?redirect_to=/push-notification?login_completed=1';
	} else {
		return `${GOOGLE_LOGIN_REDIRECT_URL}/apps/${projectId}/push-notification?login_completed=1`;
	}
};
const StepOne = () => {
	const formData = useFormData();
	// const setAccessToken = (googleCredential) => {
	// 	const { _tokenResponse = {}, user: userDetails = {} } = googleCredential;
	// 	const { oauthAccessToken } = _tokenResponse;
	// 	if (oauthAccessToken) {
	// 		setFormData({
	// 			...formData,
	// 			currentStep: formData.currentStep + 1,
	// 			accessToken: oauthAccessToken,
	// 			userDetails,
	// 		});
	// 	}
	// };
	return (
		<div className="flex flex-col items-center justify-center flex-auto">
			<img
				src="/images/misc/firebase-logo.png"
				alt=""
				className="h-24 w-24 bg-yellow-100 p-2 rounded-2xl mb-2 drop-shadow-lg"
			/>
			<h2 className="text-lg font-medium">Firebase Configuration</h2>
			<p className="text-base text-gray-500 mb-4">
				Login with google to firebase to enable push-notification.
			</p>
			{/* <button
			type="button"
			className="inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
		>
			Continue with Google
		</button> */}
			{/* <GoogleLogin
				className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
				getCredential={setAccessToken}
			>
				<LoginIcon className="h-5 w-5 mr-2" />
			</GoogleLogin> */}
			<GoogleLogin
				label="Continue with Google"
				className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
				redirectUrl={generateRedirectUrl()}
			>
				<LoginIcon className="h-5 w-5 mr-2" />
			</GoogleLogin>
		</div>
	);
};

export default StepOne;
