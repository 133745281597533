// import appmaker from '@appmaker/core/index';
// appmaker.actions

const actions = {
    NO_ACTION: {
        label: "No action"
    },
    OPEN_IN_WEBVIEW: {
        label: "Open webview",
        properties: {
            url: {
                label: "Choose URL",
                type: 'string'
            },
            title:{
                type:"string",
                label:"Title",
                
            },
            extraStyle: {
                label: "Extra Style",
                type: 'string'
            },
            openInModal:{
                type:"switch",
                label:"Open in Modal",
            }
        }
    },

    OPEN_URL: {
        label: "Open URL",
        properties: {
            url: {
                label: "Choose URL",
                type: 'string'
            },

        }
    },
}

export default actions;