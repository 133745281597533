import axios from '@appmaker/core/axios';
import { BASE_URL, V3_BASE_URL } from '@appmaker/core/api/core';
import coreApi from '@appmaker/core/api/core';
const apiFunction = {
	getFirebaseProjectList: async (projectId, accessToken, nextPageToken) => {
		let url = `${V3_BASE_URL}/projects/${projectId}/notifications/getAllProjects`;
		if (nextPageToken) {
			url += `?nextPageToken=${nextPageToken}`;
		}
		const response = await axios.get(url, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
	getFirebaseProjectData: async (projectId, accessToken, projectName) => {
		const url = `${V3_BASE_URL}/projects/${projectId}/notifications/firebase-app-data?projectName=${projectName}`;
		const response = await axios.get(url, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
	createApp: async ({ accessToken, projectName, platform, projectId }) => {
		const url = `${V3_BASE_URL}/projects/${projectId}/notifications/create-app`;
		const response = await axios.post(
			url,
			JSON.stringify({ projectName, accessToken, platform }),
			{
				credentials: 'include',
				withCredentials: true,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			}
		);
		return response.data;
	},
	operations: async ({ accessToken, operationId, projectId }) => {
		const url = `${V3_BASE_URL}/projects/${projectId}/notifications/operation`;
		const response = await axios.post(
			url,
			JSON.stringify({ operationId, accessToken }),
			{
				credentials: 'include',
				withCredentials: true,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			}
		);
		return response.data;
	},
	uploadAppToCloud: async ({
		accessToken,
		projectName,
		projectId,
		platform,
	}) => {
		try {
			const url = `${V3_BASE_URL}/projects/${projectId}/notifications/upload-to-gcloud?accessToken=${accessToken}&projectName=${projectName}&platform=${platform}`;
			const response = await axios.get(url, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	uploadService: async ({ file, projectId }) => {
		try {
			const data = new FormData();
			data.append('file', file);
			data.append('id', projectId);
			const url = `${V3_BASE_URL}/projects/${projectId}/notifications/upload-service-to-gcloud`;
			const response = await axios.post(url, data, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	addFcmServerKey: async ({ projectId, data }) => {
		try {
			const url = `${BASE_URL}/projects/${projectId}/notifications/add-fcm`;
			const response = await axios.post(url, JSON.stringify(data), {
				credentials: 'include',
				withCredentials: true,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	buildApp: coreApi.buildProject,
	sendPushNotification: async ({ projectId, data, topic = 'general' }) => {
		try {
			const url = `${V3_BASE_URL}/notification-webhooks/schedule/${projectId}/${topic}`;
			const response = await axios.post(url, JSON.stringify(data), {
				credentials: 'include',
				withCredentials: true,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	searchFirebaseProject: async ({ projectId, searchText, accessToken }) => {
		try {
			const url = `${V3_BASE_URL}/projects/${projectId}/notifications/search-project?accessToken=${accessToken}&search=${searchText}`;
			const response = await axios.get(url, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	getScheduledNotifications: async ({ projectId }) => {
		let url = `${V3_BASE_URL}/projects/${projectId}/notifications/scheduled`;
		const response = await axios.get(url, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
	getHistory: async ({ projectId }) => {
		let url = `${V3_BASE_URL}/projects/${projectId}/notifications/history`;
		const response = await axios.get(url, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
	cancelScheduledNotification: async ({ projectId, rowId }) => {
		let url = `${V3_BASE_URL}/notification-webhooks/schedule/${projectId}?rowId=${rowId}`;
		const response = await axios.delete(url, {
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
};

export default apiFunction;
